import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Logo from './logo'
import Contact from './Contact'
import CustomerService from './CustomerService'
import bg from './../../assets/cov.png'
import BottomFooter from './ButtomFooter'
const Footer = () => {

    return (
        <>
            <footer>
                <Box sx={{ width:'75%', margin:'auto', mb:'-2%' }} >
                    <Box position={'relative'} sx={{ backgroundImage: `url(${bg})`, height: '10rem', display: { md: 'flex' ,xs:'none'}, alignItems: 'center', backgroundSize: 'cover', position: 'relative', width:'100%',justifyContent:'space-around', px:4  }} >
                        <Box>
                            <Button variant="contained"
                                type="contained"
                                sx={{
                                    backgroundColor: '#F12020', ':hover': {
                                        backgroundColor: '#F12020'
                                    },
                                    color: "#fff",
                                    fontWeight: 'bold',
                                }}>Book Now</Button>
                        </Box>
                        <Box>
                            <Typography variant='h5'>EMERGENCY CALL 24/7</Typography>
                            <Typography variant='h5'>BOOK AN APPOINTMENT TODAY</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h4'><a href="tel:+17206786516" style={{ color: '#000', textDecoration: 'none' }} >+17206786516</a></Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    background: `#1D1D1B`,
                }} >
                    <Box sx={{ py: 1, px: 3, zIndex: 9 }} >
                        <Container maxWidth={'lg'} >
                            <Grid container spacing={1} alignItems={'center'} >
                                <Grid item md={4} xs={12}>
                                    <Logo />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Contact />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <CustomerService />
                                </Grid>
                            </Grid>
                            <BottomFooter />
                        </Container>
                    </Box>
                </Box>
            </footer>
        </>
    )
}

export default Footer