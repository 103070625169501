import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Pages } from "../../constants";
import { IconButton } from "@mui/material";
import logo from './../../assets/logo.png'
import CancelIcon from '@mui/icons-material/Cancel';
export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor={'left'}
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    sx={{ 
                        '.MuiDrawer-paper': {
                            height: '80% !important ',
                            borderBottomRightRadius: '16px',
                            overflowY: 'auto',
                            backgroundColor: 'rgba(0, 0, 0, 0.65)'
                        }
                    }}
                    >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: 'left' ,
                            padding: "40px 20px",
                        }}>
                        <Box
                            sx={{
                                flexGrow: 0,
                                display: { md: "none", xs: "flex" },
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                            }}>
                            <Link to="/">
                                <img src={logo} style={{ height:'fit-contain' }} alt="logo" className="nav-logo" ></img>
                            </Link>
                            <IconButton onClick={() => setDrawer(false)}>
                                <CancelIcon sx={{ color:'#FABB25' }} />
                                {/* <img src={close} style={{ height:'20px' }} alt="close" ></img> */}
                            </IconButton>
                        </Box>
                        {
                            Pages?.map((page, i) => (
                                <Link
                                    key={i}
                                    to={page.path}
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                    onClick={() => setDrawer(false)}
                                >
                                    { page.name_en}
                                </Link>
                            ))
                        }
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
