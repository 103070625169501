import { useTheme } from '@emotion/react';
import { Box, Button, CardMedia, Divider, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import m1 from './../../assets/contacts/email.png'
import m2 from './../../assets/contacts/phone.png'

const ContactData = () => {
  const themeM = useTheme();
  const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
  return (
    <>
      <Box sx={{ width: { md: '50%', xs:'100%' }, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }} >
          <CardMedia src={m1} component={'img'} sx={{ width: '15%' }} />
          <Box>
            <Typography color={'#FFF'} variant={isMobile ? 'h6' : 'h5'} sx={{ my: 1.2 }} >{`Email`}</Typography>
            <Divider sx={{ borderColor: '#D5AB4D', borderWidth: '2px', width: '3rem' }} />
            <Typography color={'#FFF'} variant={'h6'} sx={{ my: 1.2 }} >{`Goodhands2024@gmail.com `}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }} >
          <CardMedia src={m2} component={'img'} sx={{ width: '15%' }} />
          <Box>
            <Typography color={'#FFF'} variant={isMobile ? 'h6' : 'h5'} sx={{ my: 1.2 }} >{`Phone`}</Typography>
            <Divider sx={{ borderColor: '#D5AB4D', borderWidth: '2px', width: '3rem' }} />
            <Typography color={'#FFF'} variant={'h6'} sx={{ my: 1.2 }} >
              <a href="tel:+17206786516" style={{ color:'#FFF', textDecoration:'none' }} >
              {`+17206786516`}
              </a>
              </Typography>
          </Box>
        </Box>
        <Box mt={2} >
          <Button variant="contained"
            type="contained"
            sx={{
              backgroundColor: '#F12020', ':hover': {
                backgroundColor: '#F12020'
              },
              color: "#fff",
              fontWeight: 'bold',
            }}>Book Now</Button>
        </Box>
      </Box>
    </>
  )
}

export default ContactData