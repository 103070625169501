import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import './style.css'
// import theme from '../../util/theme'
// import Btn from '../glopal/Btn'
import icon from './../../assets/sliderImg/Group 2847.png'
import { useTheme } from '@emotion/react'
const HeroText = ({ title, desc, btn }) => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems:'center', justifyContent:'center' }}>
                <Typography variant={isMobile ? 'body1' : 'h4'} color={'#fff'} >{title}</Typography>
                <Typography variant={isMobile ? 'body1' : 'body1'} color={'#fff'} >{desc}</Typography>
                <Box sx={{
                    width: 'fit-content', borderRadius: 1, display: 'flex', gap: 1, alignItems: 'center', p: 1, background:'#D5AB4D' }} >
                    <CardMedia 
                        component={'img'}
                        src={icon}
                        sx={{ width:'35px', height:'35px' }}
                    />
                    <Typography color={'#FFF'}><a href="tel:+17206786516" style={{ color: '#FFF', textDecoration: 'none' }} >+17206786516</a></Typography>
                </Box>
            </Box>
        </>
    )
}

export default HeroText