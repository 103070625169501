import React from 'react'
import Header from '../components/glopal/Header'
// import { Box, Typography, } from '@mui/material'
import About from '../components/AboutUs/Index'
// import Title from '../components/glopal/Title'
// import Desc from '../components/WelcomeToZara/Desc'
// import img from './../assets/Group 2894.png'
const AboutUs = () => {
    return (
        <>
            <Header url={'About Us'} />
            <About />
        </>
    )
}

export default AboutUs