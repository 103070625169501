import { CardMedia, useMediaQuery } from '@mui/material';
import React from 'react'
import Carousel from "react-material-ui-carousel";
// import slideImg from './../../assets/sliderImg/5.png'
import slideImg1 from './../../assets/services/1.jpg'
import slideImg2 from './../../assets/services/10.jpg'
import slideImg3 from './../../assets/services/19.jpg'
import slideImg4 from './../../assets/services/14.jpg'
import slideImg5 from './../../assets/services/13.jpg'
import { useTheme } from '@emotion/react';
const Sliders = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    const images = [
        // slideImg,
        slideImg1,
        slideImg2,
        slideImg3,
        slideImg4,
        slideImg5
    ]
    return (
        <>
            <Carousel
                animation='slide'
                navButtonsAlwaysInvisible
                indicatorIconButtonProps={{
                    style: {
                        padding: '0',
                        color: 'transparent',
                        border: '1px solid #FABB25',
                        '&:hover': {
                            opacity: "0.6 !important"
                        },
                    }
                }}

                activeIndicatorIconButtonProps={{
                    style: {
                        color: '#FABB25',
                    }
                }}
                indicatorContainerProps={{
                    style: {
                        marginTop: '10px',
                        marginLeft: '10px',
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: '50%',
                        zIndex: 9,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        left: isMobile ? '85%' : '95%',
                        width: 'fit-content',
                        transform: 'translateY(50%)'
                    }
                }}
            >
                {images?.map((img, i) =>
                    <CardMedia
                        key={i}
                        sx={{
                            height: {
                                md: "35rem", xs: "300px",
                            },
                            width: "100%",
                            objectFit: "cover",
                        }}
                        component="img"
                        alt={img}
                        src={img} />)}
            </Carousel>
        </>
    )
}

export default Sliders