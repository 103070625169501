import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Card from './Card'
import img1 from './../../assets/reviews/1.png'
import img from './../../assets/reviews/2.png'
import img3 from './../../assets/reviews/1.png'
// import Btn from '../glopal/Btn'
const ListReviews = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box py={2} >
                    <Box p={2} width={'100%'} >
                        <Typography variant='h5' textAlign={'center'} color='#D5AB4D'>Here's what our customers say</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Card
                                // name={'sarwar modabber'}
                                // rev={5}
                                img={img1}
                                // revDesc={`I got my dream car. Thank you so much.`}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card
                                // name={'Nassim Allou'}
                                // rev={5}
                                img={img}
                                // revDesc={`A good experience, thank you very much,good reception`}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card
                                // name={'Suyapa Arita'}
                                // rev={5}
                                img={img3}
                                // revDesc={`Thank you very much for my new car.`}
                            />
                        </Grid>
                    </Grid>
                </Box>
                
            </Container>
        </>
    )
}

export default ListReviews