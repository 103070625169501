import { Box, Container, Typography } from '@mui/material'
import React from 'react';
// import Card from './Card'
// import { services } from '../../constants'
// import Filter from '../glopal/Filter'
// import CardRow from './CardRow'
// import { useGetVehiclesQuery } from '../../state/apiServices';
import Title from '../glopal/Title';
// import OurServices from '../OurServices';
import ExploreNewProjects from './ExploreNewProjects';
import ListReviews from '../Reviews/ListReviews';

const ListServices = () => {
    // const [row, setRow] = useState(false)
    // const { data, isBrandsLoading } = useGetVehiclesQuery();

    // const [tableData, setTableData] = useState([]);
    // useEffect(() => {
    //     if (data && !isBrandsLoading) {
    //         setTableData(data?.data)
    //     }
    // }, [data, tableData, isBrandsLoading])

    return (
        <>
            <Container maxWidth={'lg'} >
                <Box py={4} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: { md: 1, xs: 1 }, flexDirection: { md: 'column', xs: 'column' }, py: 2 }} >
                        <Title title={'What We Do'} />
                        <Typography sx={{ color: '#939494', textAlign: 'center' }} >
                            We are a towing company located in Denver, Co. We are proud to say that we have been providing Towing Trucks and Roadside Assistance
                            services in Denvefor Long Time. Good Hands Towing offers 24 hours towing in Denver and its surrounding areas, road side
                            assistance, and automotive services. No matter what the issue is, We will be happy to send out a fully equipped tow truck to you with a trained and
                            professional technician to assist you.
                        </Typography>
                    </Box>
                    <ListReviews />
                    {/* <OurServices /> */}
                    <ExploreNewProjects />
                    {/* <Filter onSetRow={setRow}/>
                    <Grid container spacing={2}>
                        {
                            tableData?.map((service) => <>
                                {
                                    row ?
                                    <Grid item md={12} xs={12}>
                                        <CardRow
                                                img={service?.image}
                                                title={`${service?.year} ${service?.brand?.name}`}
                                                supTitle={`${service?.model?.name} ${service?.type?.name}`}
                                                Stock={service?.stock_number}
                                                Engine={service?.engine}
                                                Trans={service?.transmission?.name}
                                                Drive={service?.drive?.name}
                                                Mileage={service.mileage}
                                                Color={service.color?.name}
                                                VIN={service?.vin}
                                                Retail={service?.price}
                                        />
                                    </Grid> :
                                        <Grid item md={4} xs={12}>
                                            <Card
                                                img={service?.image}
                                                title={`${service?.year} ${service?.brand?.name}`}
                                                supTitle={`${service?.model?.name} ${service?.type?.name}`}
                                                Stock={service?.stock_number}
                                                Engine={service?.engine}
                                                Trans={service?.transmission?.name}
                                                Drive={service?.drive?.name}
                                                Mileage={service.mileage}
                                                Color={service.color?.name}
                                                VIN={service?.vin}
                                                Retail={service?.price}
                                            />
                                        </Grid>
                                }
                            </>
                            )
                        }
                    </Grid> */}
                </Box>
            </Container>
        </>
    )
}

export default ListServices