import { Box, Typography } from '@mui/material'
import React from 'react'
// import { Pages } from '../../../constants'
// import { Link } from 'react-router-dom'

const BottomFooter = () => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                <Box><Typography color={'primary.main'} textAlign={'center'} p={2} >
                    Copyright 2024 | Good Hands Towing
                </Typography></Box>
                <Box><Typography color={'primary.main'} textAlign={'center'} p={2} >
                    Powered by <a href="https://revampbrands.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration:'none' }} >
                        <span style={{ color: '#ffb001' }} >Revamp </span>
                    </a>
                </Typography></Box>
            </Box>
        </>
    )
}

export default BottomFooter