import React from 'react'
import { Box, Divider, Typography } from '@mui/material'

const CustomerService = () => {

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', p: 1 }} >
        <Typography color={'#D5AB4D'} variant='h6' >Good Hands Towing </Typography>
        <Divider sx={{ borderColor: '#D5AB4D', borderWidth: '2px', width: '5rem', mb:1 }} />
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }} >
          <Typography color={'#F3F3F3'} >{`Contact us for towing service in Denver, Co,
 Roadside assistance, car lockout, fuel delivery, flatbed
 towing, battery jump start or any other issue related to 
your car.`}</Typography>
        </Box>
        {/* <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
            <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Sunday'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'Closed'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Monday:'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'10:00 AM - 07:00 PM'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Tuesday:'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'10:00 AM - 07:00 PM'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Wednesday:'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'10:00 AM - 07:00 PM'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Thursday:'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'10:00 AM - 07:00 PM'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:'100%', justifyContent:'space-between' }} >
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'50%' }}  >{'Friday:'}</Typography>
          <Typography color={'#F3F3F3'} sx={{ fontSize:'0.8rem', width:'75%' }}>{'10:00 AM - 07:00 PM'}</Typography>
        </Box> */}
      </Box>
    </>
  )
}

export default CustomerService